// Libs
import React, { useState, useEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import FeaturedMarketing from 'components/featuredMarketing';
import Publications from 'components/Publications';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  padding-bottom: ${props => props.paddingBottom && '70px'};
  border-top: ${props => props.borderTop && '1px solid #C6C8CC'};
  padding: ${props => (props.isFeaturedMarketing ? 'unset' : '4.375rem 0')};

  :last-child {
    border-bottom: ${props => props.borderBottom && 'unset'};
  }

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media ${device.laptop} {
      margin-bottom: 0;
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
    padding: ${props =>
      props.isFeaturedMarketing ? 'unset' : '1.25rem 0 4.375rem'};
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
    padding: 1.25rem 0 4.375rem;
  }
  @media ${device.laptop} {
    border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
    :last-child {
      border-bottom: ${props => props.borderBottom && 'unset'};
    }
    padding: ${props =>
      props.isFeaturedMarketing ? 'unset' : '1.25rem 0 4.375rem'};
  }
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const getGraphCMSContent = (page, fragmentId) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (!findFragment) throw new Error(`Fragment '${fragmentId}' not found.`);

  if (findFragment.localizations?.length === 1) {
    return flatten(findFragment.localizations)[0];
  } else if (findFragment.localizations?.length > 1) {
    return flatten(findFragment.localizations);
  }

  return findFragment;
};

const getFragmentsOfPublications = (page, excludedFragments = []) => {
  const { fragments } = page[0];
  if (!(excludedFragments instanceof Array))
    throw new Error('excludedFragments must be an array');

  const findFragment = fragments.reduce((acc, item) => {
    const foundInExcludedFragment = excludedFragments.find(
      excludedFragment => excludedFragment === item.id
    );

    if (foundInExcludedFragment) return acc;

    return (acc = [...acc, item.id]);
  }, []);

  return findFragment;
};

const renderPage = (page, isDesktop) => {
  const publications = getFragmentsOfPublications(page, [
    'cku722h5clqxm0c73e279mif1',
    'cku723s7slr930c28ukgeka40',
  ]);

  return (
    <GridContainer isDesktop={isDesktop}>
      <Part gridColumn={'2 / -2'} isFeaturedMarketing>
        <FeaturedMarketing
          markdown={getMarkdown(page, 'cku723s7slr930c28ukgeka40', true)}
          breadCrumb={getMarkdown(page, 'cku722h5clqxm0c73e279mif1', true)}
          asset={getMarkdown(page, 'cku723s7slr930c28ukgeka40', false, true)}
          isOncologia
          boxInfoTextWidthDesk="28.125rem"
          boxContentPaddingBottomDesk="0"
          boxInfoParagraphImgWidth="unset"
          boxInfoParagraphImgHeight="unset"
          marginTopTitle="3.125rem"
          titleMarginLap="0"
          containerNoPaddingTopLap
          boxImagesImgOuterWrapperWidth="unset"
          marginTopBoxInfoMob="-8rem"
          marginTopBoxInfoTab="-9rem"
          positionLeftImageMarketingMobile320="1.725rem"
          positionLeftImageMarketingMobile="0.7rem"
          overflowContainerMobile="hidden"
          positionTopImageMarketingMobile="0.rem"
          widthImgMarketingMobile="10rem"
          widthImgMarketingTab="17rem"
          widthImgMarketingLap="25rem"
          marginBottomTitleDesk="2.5rem"
          boxContentFlexDirectionTab="column-reverse"
          boxContentFlexDirectionLap="unset"
          titleWidthDesk="26rem"
          titleWidthLap="14rem"
          titleWidthTab="14rem"
          titleWidthMob="14rem"
          titleWidthMob320="50%"
          titleFontSizeMob360="1.25rem"
          titleFontSizeMob320="1.125rem"
          boxInfoHeightLap="9rem"
          widthImgMarketingDesk="33.75rem"
          heightImgMarketingDesk="27.625rem"
          ImgAdjustWidth="35.625rem !important"
          ImgAdjustHeight="35.625rem !important"
          marginBreadCrumb="0 0 0"
          justifyContentFeaturedMarketing="end"
          boxInfoHeightMobile="9rem"
        />
      </Part>
      {publications.map((publication, index, publicationArray) => (
        <Part
          key={index}
          gridColumn={'2 / -2'}
          borderBottom={publicationArray.length - 1 !== index}
        >
          <Publications content={getGraphCMSContent(page, publication)} />
        </Part>
      ))}
    </GridContainer>
  );
};
const PublicationsPage = ({ page }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  const getImage = getMarkdown(page, 'cku723s7slr930c28ukgeka40', false, true);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={
          `${process.env.REACT_APP_GRAPHCMS_ASSETS_URL}/${
            getImage && getImage.handle
          }` || '-'
        }
      />
      {renderPage(page, isDesktop)}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cku6ywsk0jecm0b71ioz5wqp3" }) {
                fragments {
                  id
                  name
                  singletexts
                  markdown
                  datafragment
                  localizations {
                    singletexts
                    markdown
                    datafragment
                  }
                  assetpicker {
                    handle
                    width
                    height
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <PublicationsPage page={response.gcms.site.pages} />;
      }}
    />
  );
};
