import React, { useEffect, useRef, useState } from 'react';

import {
  Container,
  Title,
  Content,
  Publication,
  PublicationThumbnail,
  PublicationTitle,
  PublicationDate,
  PublicationDescription,
} from './style';

const Publications = ({ content }) => {
  const [activeSlider, setActiveSlider] = useState(false);
  const contentRef = useRef(null);

  const contentSlider = contentRef;
  let isMouseDown = false;
  let listenerPositionX;
  let elementScrollLeft;

  useEffect(() => {
    contentSlider.current.addEventListener('mousedown', listener => {
      isMouseDown = true;
      listenerPositionX = listener.pageX - contentSlider.current.offsetLeft;
      elementScrollLeft = contentSlider.current.scrollLeft;
    });
    contentSlider.current.addEventListener('mouseleave', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mouseup', () => {
      isMouseDown = false;
      setActiveSlider(false);
    });
    contentSlider.current.addEventListener('mousemove', listener => {
      if (!isMouseDown) return;
      listener.preventDefault();
      setActiveSlider(true);
      const positionX = listener.pageX - contentSlider.current.offsetLeft;
      const distance = (positionX - listenerPositionX) * 2; //change multiply value to change scroll-speed
      contentSlider.current.scrollLeft = elementScrollLeft - distance;
    });
  }, []);

  return (
    <Container>
      <Title>{content?.singletexts?.[0] || ''}</Title>
      <Content ref={contentRef} activeSlider={activeSlider}>
        {content?.datafragment?.map((data, index) => (
          <Publication key={index}>
            <PublicationThumbnail>
              {data.thumbnail ? (
                <a
                  href={data.file_link || '#'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={data.thumbnail || ''} alt={data.title || ''} />
                </a>
              ) : (
                <></>
              )}
            </PublicationThumbnail>
            <PublicationTitle>
              {data.file_link ? (
                <a href={data.file_link} target="_blank" rel="noreferrer">
                  {data.title || ''}
                </a>
              ) : (
                data.title || ''
              )}
            </PublicationTitle>
            <PublicationDate>
              {data.edition} - {data.date || ''}
            </PublicationDate>
            <PublicationDescription>
              {data.description || ''}
            </PublicationDescription>
          </Publication>
        ))}
      </Content>
    </Container>
  );
};

export default Publications;
