import styled, { css } from 'styled-components';
import { device } from 'components/device';

export const Container = styled.section`
  width: 100%;
`;

export const Title = styled.h3`
  font-size: 1.5rem;
  line-height: 1.875rem;
  color: #231f20;
  font-weight: normal;

  margin-bottom: 1.875rem;

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-bottom: 1.25rem;
  }
`;

export const Content = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.875rem;

  @media ${device.laptop} {
    width: 100vw;
    flex-wrap: nowrap;
    overflow-x: scroll;
    margin: 0 -20px;
    padding: 0 20px;
    gap: 1.25rem;

    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    ${props =>
      props.activeSlider &&
      css`
        cursor: grabbing !important;
        user-select: none;

        a {
          pointer-events: none;
        }
      `}
  }
`;

export const Publication = styled.div`
  width: 100%;
  max-width: 16.875rem;
`;

export const PublicationThumbnail = styled.div`
  width: 16.875rem;
  height: 9.375rem;
  background-color: #e0e0e0;
  margin-bottom: 1.875rem;

  img {
    width: 100%;
    max-width: 16.875rem;
    height: 100%;
    max-height: 9.375rem;
    object-fit: cover;
    object-position: center;
  }
`;

export const PublicationTitle = styled.h5`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  font-weight: normal;

  margin-bottom: 0;

  a {
    color: #45a7df;
  }

  @media ${device.laptop} {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
`;

export const PublicationDate = styled.span`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  font-weight: normal;

  margin-bottom: 0;
`;

export const PublicationDescription = styled.p`
  font-size: 1rem;
  line-height: 1.625rem;
  color: #231f20;
  font-weight: normal;

  margin-top: 1.875rem;
`;
